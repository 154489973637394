<template>
  <v-app>
    <section>
      <h1 class="text-center text-gold">{{ $t('Terms of Use and Privacy Policy') }}</h1>
      <article class="mt-10" v-for="(clause, i) in clauses" :key="i">
        <h3>{{ `${++i}. ${clause.title}`}}</h3>
        <p class="mt-1">{{ clause.content }}</p>
      </article>
      <v-divider class="mt-10"></v-divider>
    </section>
  </v-app>
</template>
<script>
export default {
  name: 'TermsAndPolicy',
  data: () => ({
    clauses: [
      {
        title: 'Compartilhamento e divulgação do conteúdo',
        content: 'Ao compartilhar/divulgar alguma mensagem ou texto, mantenha os créditos a nós.'
      },
      {
        title: 'Coleta de dados do usuário',
        content: 'As informações coletadas por nós são apenas as que você preenche ao se cadastrar. Se você se cadastra através de alguma rede social, ' 
        + 'coleta-se seu nome, email e data de nascimento.'
      }
    ]
  })
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_classes.scss';
  h1 { 
    font-family: 'Pacifico', cursive; 
    text-shadow: 1px 1px 1px gray;
  }

  .v-divider {
    max-width: 350px;
    margin: 0 auto;
    border-width: thin 0 1px 0;
    border-color: gold !important;
  }
</style>

